<template>
  <b-card>
    <validation-observer ref="conviteForm" #default="{invalid}">
      <b-row>
        <b-col cols="3">
          <validation-provider #default="{ errors }" name="Nome" rules="required|min:2|max:255" vid="Nome">
            <b-form-group label="Nome" label-for="name">
              <b-form-input id="name" v-model="name" name="name" :state="errors.length > 0 ? false:null" no-wheel />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col cols="3">
          <b-form-group label="Email" label-for="email">
            <validation-provider #default="{ errors }" name="Email" vid="email" rules="required|email">
              <b-form-input id="email" v-model="user_email" :state="errors.length > 0 ? false:null" name="email" />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <b-form-group label="CPF" label-for="cpf">
              <validation-provider #default="{ errors }" name="CPF" rules="required|cpf" vid="cpf">
                <b-form-input id="cpf"
                              v-model="cpf"
                              v-mask="'###.###.###-##'"
                              :state="errors.length > 0 ? false : null"
                              name="cpf"
                              type="text"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
        </b-col>
        <b-col cols="3">
          <validation-provider #default="{ errors }" name="Celular" rules="required" vid="Celular">
            <b-form-group label="Celular" label-for="celular">
              <b-form-input id="celular" v-model="celular" name="celular" v-mask="['(##) # ####-####']" :state="errors.length > 0 ? false:null" no-wheel />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>
      <b-row class="button-position">
        <b-col cols="12" class="align-items-end text-end mt-1">
          <b-button variant="primary" :disabled="invalid" class="" @click="createConvite">
            Enviar Convite
          </b-button>
        </b-col>
      </b-row>
    </validation-observer>
    <b-row>
      <b-col cols="3">
        <b-form-group label="Grupo econômico" label-for="grupo-economico">
          <v-select
            id="grupo-economico"
            v-model="grupoEconomico"
            :value="null"
            name="grupo-economico"
            :clearable="true"
            :options="grupoEconomicoOptions"
          />
        </b-form-group>
      </b-col>

      <b-col cols="3">
        <div class="d-flex justify-content-between align-items-end" v-if="grupoEconomico ===  null">
          <template>
            <div class="w-100">
              <label class="mb-sm">CNPJs</label>
              <div class="p-0 m-0">
                <div class="cnpjListHeader">
                  <validation-observer ref="businessGroup" #default="{ invalid }"
                    class="d-flex flex-row justify-content-between align-items-start">
                    <validation-provider #default="{ errors }" mode="lazy" vid="cnpj" name="CNPJ"
                      :rules="`required|cnpj|cnpjIsNotIncluded:${cnjpCustomRule}`" class="w-100">
                      <b-form-input id="cnpj" v-model="cnpj" :state="errors.length > 0 ? false : null"
                        placeholder="Incluir novo" v-mask="'##.###.###/####-##'" @blur="handleInputValidation" />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                    <b-button variant="outline-primary" :disabled="invalid" class="text-nowrap ml-1" @click="addCnpj">
                      Incluir <feather-icon icon="PlusIcon" />
                    </b-button>
                  </validation-observer>
                </div>
              </div>
              <div class="cnpjListBody border-gray scroll" v-if="cnpjs.length > 0">
                  <b-list-group flush>
                    <b-list-group-item v-for="(cnpj, index) in cnpjs" :key="index">
                      <div class="d-flex justify-content-between align-items-center">
                        {{ cnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5') }}
                        <b-button variant="flat-primary" size="sm" @click="deleteCnpj(index)"> Excluir <feather-icon
                      icon="Trash2Icon" /> </b-button>
                      </div>
                    </b-list-group-item>
                  </b-list-group>
              </div>
            </div>
          </template>
        </div>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { BCard, BRow, BCol, BButton, BListGroup, BListGroupItem, BFormInput, BFormGroup } from "bootstrap-vue";
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import vSelect from 'vue-select'
import { required, email, min, max } from '@validations'
import http from "@/services/http";

export default {
  name: 'CreditAdvanceCard',
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BListGroup,
    BListGroupItem,
    BFormInput,
    BFormGroup,
    ValidationObserver,
    ValidationProvider,
    vSelect
  },
  data() {
    return {
      cnpjs: [],
      cnpj: '',
      name: '',
      user_email: '',
      cpf: '',
      celular: '',
      grupoEconomico: null,
      grupoEconomicoOptions: [],
      required,
      email,
      min,
      max
    }
  },
  beforeMount() {
    http.get('backoffice/grupos_economicos').then(res => {
      this.grupoEconomicoOptions = res.data.map((grupo_economico) => ({
        value: grupo_economico.id,
        label: grupo_economico.nome,
      }));
    }).catch(err => {
      console.log(err)
    })
  },
  methods: {
    createConvite() {
      let grupo_economico = null
      let selectedCnpjs = null

      if(this.grupoEconomico !== null) {
        grupo_economico = this.grupoEconomico.value
      } else {
        selectedCnpjs = this.cnpjs
      }

      let body ={
        convite: {
          nome_contato: this.name,
          email_contato: this.user_email,
          cpf: this.cpf,
          celular_contato: this.celular,
          grupo_economico_id: grupo_economico,
          cnpjs: selectedCnpjs
        }
      }

      http.post('backoffice/admin/convites', body).then(res => {
        if(res.status == 201) {
          this.$swal.fire({
            title: 'Sucesso!',
            text: 'Convite criado com sucesso!',
            icon: 'success',
          })
          return this.$router.push({ name: "admin-convites.index" });
        }
      }).catch( err => {
        console.log(err)
      })

    },
    async addCnpj() {
      if (!this.cnpj) return

      this.cnpjs.push(this.cnpj)
      this.cnpj = '';
      await this.$refs.businessGroup.reset()
    },
    async handleInputValidation($event) {
      try {
        this.cnpj = $event.target.value;
        this.cedenteAlreadyExists = Boolean(this.cnpjs.find(cnpj => cnpj === this.cnpj))
      } catch (error) {
        console.log(error);
        await this.$refs.businessGroup.reset()
        this.cnpj = '';
      }
    },
    async deleteCnpj(index) {
      this.cnpjs.splice(index, 1)
      await this.$refs.businessGroup.reset()
      this.cnpj = '';
    },
  },
  computed: {
    cnjpCustomRule() {
      return this.cnpjs.join(',')
    },
  }
}
</script>
<style lang="scss">
.text-end {
  text-align: end;
}
.mb-sm {
  font-size: 10px;
  margin-bottom: 2px
}
.border-gray {
  border: 1px solid #DAE1E8;
}

.scroll {
  overflow: auto;
  max-height: 250px;
}

.button-position {
  position: absolute;
  bottom: 34px;
  right: 22px;
}
</style>
