import { render, staticRenderFns } from "./novoConvite.vue?vue&type=template&id=69a94688&"
import script from "./novoConvite.vue?vue&type=script&lang=js&"
export * from "./novoConvite.vue?vue&type=script&lang=js&"
import style0 from "./novoConvite.vue?vue&type=style&index=0&id=69a94688&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports